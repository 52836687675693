import { useEffect, useState } from 'react'
import {
  faAngular,
  faCss3,
  faGitAlt,
  faHtml5,
  faJsSquare,
  faReact,
} from '@fortawesome/free-brands-svg-icons'
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters/Index'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './Index.scss'

const About = () => {
  const [letterClass, setLetterClass] = useState('text-animate')

  useEffect(() => {
    return setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 3000)
  }, [])

  return (
    <>
    
      <div className="container about-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={['A', 'b', 'o', 'u', 't', ' ', 'm', 'e']}
              idx={15}
            />
          </h1>
          <h4>
          My experience: includes writing Blog Posts, Articles, Social Media Content, Website Copy, and more.

          </h4>
          <p align="LEFT">
          I have always had a deep appreciation for words and their power to engage, inform, and inspire. Over the past 1 year , I have honored my skills as a content writer, working with various clients and organizations to operate and informative content across a wide range of topics.

          </p>
          
        <strong>  Exceptional Writing Skills: </strong>
        <p>
I have a strong command of Grammar, Punctuation, and Style, Allowing Me To Create High-Quality Content That Is Both Enaging and error-free. </p>
<strong>

SEO KNOWLEDGE : </strong>
<p>I am family with search engine optimization (SEO) techniques and can Incorporate Relevant keywords and optimize content to improve visibility and search engine rankings.</p>
<strong>

Adaptability : </strong>
<p>
I am accustomed to working in a fast-paced environment and has the ability to adapt my writing style to the tone and voice of different brands and target audiences.
</p>
        </div>

        <div className="stage-cube-cont">
          <div className="cubespinner">
            <div className="face1">
              <FontAwesomeIcon icon={faAngular} color="#DD0031" />
            </div>
            <div className="face2">
              <FontAwesomeIcon icon={faHtml5} color="#F06529" />
            </div>
            <div className="face3">
              <FontAwesomeIcon icon={faCss3} color="#28A4D9" />
            </div>
            <div className="face4">
              <FontAwesomeIcon icon={faReact} color="#5ED4F4" />
            </div>
            <div className="face5">
              <FontAwesomeIcon icon={faJsSquare} color="#EFD81D" />
            </div>
            <div className="face6">
              <FontAwesomeIcon icon={faGitAlt} color="#EC4D28" />
            </div>
          </div>
        </div>
      </div>
      <Loader type="pacman" />
    </>
  )
}

export default About
