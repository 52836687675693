import './Index.scss'
import { useState } from 'react'
import LogoS from '../../assets/images/harriot.png'
import LgoSubtitle from '../../assets/images/1.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {

  faSkype,
  faWhatsapp,
  faFacebook,
  faInstagram,
  faSnapchat,
  faGithub,
} from '@fortawesome/free-brands-svg-icons'
import {
  faHome,
  faUser,
  faEnvelope,

  faClose,
  faToggleOff,
  faGraduationCap,

} from '@fortawesome/free-solid-svg-icons'
import { Link, NavLink } from 'react-router-dom'

const Sidebar = () => {
  const [showNav, setShowNav] = useState(false);

  return (
    <div className="nav-bar">
      <Link 
        className="logo"
        to="/"
        onClick={() => setShowNav(false)}>
      <img src={LogoS} alt="Logo"/> 
        <img className="sub-logo" src={LgoSubtitle} alt="james Harriot" />
      </Link>
      <nav className={showNav ? 'mobile-show' : ''}>
        <NavLink 
          exact="true"
          activeclassname="active"
          to="/"
          onClick={() => setShowNav(false)}>
          <FontAwesomeIcon icon={faHome} color="aqua" />
        </NavLink>
        <NavLink 
          activeclassname="active"
          className="about-link"
          to="/about"
          onClick={() => setShowNav(false)}>
          <FontAwesomeIcon icon={faUser} color="aqua" />
        </NavLink>
        <NavLink
          activeclassname="active"
          className="portfolio-link"
          to="/portfolio"
          onClick={() => setShowNav(false)}
        >
          <FontAwesomeIcon icon={faGraduationCap} color="aqua" />
        </NavLink>
        <NavLink
          activeclassname="active"
          className="contact-link"
          to="/contact"
          onClick={() => setShowNav(false)}
        >
          <FontAwesomeIcon icon={faEnvelope} color="aqua" />
        </NavLink>
        <FontAwesomeIcon 
          onClick={() => setShowNav(false)}
          icon={faClose}
          color="aqua"
          size="3x"
          className='close-icon' />
      </nav>
      <ul>
        <li>
          <a
            href=" https://wa.me/message/2MTUGTLMBAPJO1"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon
              icon={faWhatsapp}
              color="green"
              className="anchor-icon"
            />
          </a>
        </li>
        <li>
          <a
            href="https://join.skype.com/invite/vFIQckdKl5Zi"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon
              icon={faSkype}
              color="blue"
              className="anchor-icon"
            />
          </a>
        </li>
        <li>
          <a
            href="https://www.facebook.com/imranali13j?mibextid=ZbWKwL"
            rel="noreferrer"
            target="_blank"
          >
            <FontAwesomeIcon
              icon={faFacebook}
              color="blue"
              className="anchor-icon"
            />
          </a>
        </li>
        <li>
          <a href="https://instagram.com/imranali13james?igshid=NGExMmI2YTkyZg==" rel="noreferrer" target="_blank">
            <FontAwesomeIcon
              icon={faInstagram}
              color="red"
              className="anchor-icon"
            />
          </a>
        </li>
        <li>
          
          <a href="https://www.snapchat.com/add/jamesharriot13?share_id=fgNtVxe-xIE&locale=en-PK" rel="noreferrer" target="_blank">
            <FontAwesomeIcon
              icon={faSnapchat}
              color="yellow"
              className="anchor-icon"
            />
          </a>
        </li>
        <li>
          
          <a href="https://github.com/Imran13james/Imran13james" rel="noreferrer" target="_blank">
            <FontAwesomeIcon
              icon={faGithub}
              color="white"
              className="anchor-icon"
            />
          </a>
        </li>

      </ul>
      {/*FontAwesomeIcon is used to create icons this button work when it responsive */}
      <FontAwesomeIcon 
          onClick={() => setShowNav(true)}
          icon={faToggleOff}
          color="aqua"
          size="3x"
          className='hamburger-icon' />
          
    </div>
  )
}

export default Sidebar
