import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from 'react-loaders';
import AnimatedLetters from '../AnimatedLetters/Index';
//import 'loaders.css/loaders/ball-pulse.css';
import { css } from '@emotion/react';
//import {MoonLoader, DotLoader, BeatLoader, BounceLoader } from 'react-spinners';


import './index.scss';
import Typical from 'react-typical';

const Pertfolio = () => {
  const [letterClass1, setLetterClass1] = useState('text-animate');


  useEffect(() => {
    setTimeout(() => {
      setLetterClass1('text-animate-hover');
    }, 3000);
  }, []);


  return (
    <div className="container portfolio-page">
      <div className="text-zone">
        <h1 style={{fontFamily:'La Belle Aurore '}}>
          <AnimatedLetters
            letterClass={letterClass1}
            strArray={['E', 'd', 'u', 'c', 'a', 't', 'i', 'o', 'n']}
            idx={15}
            style={{fontFamily:'La Belle Aurore '}} />
        </h1> 
    
        <div style={{ fontSize: '22px' }}>
        
          <p align="LEFT">
          matric in 2019 from Sadiq Memorial High School, Khudian Khas, Kasur,
                  Punjab, Lahore
          </p>
          
        <p>
        Intermediate in 2021 from GCT College, Lahore, Punjab, Pakistan</p>
<p>
Web Development From UET and Ideoversity College Model Town in 2023.</p>
        </div>
      </div>
     
      <Loader type="pacman" />
      <input type="checkbox" id="toggle" />
<label htmlFor="toggle">
	<div className="floor"></div>
	
	<div className="🐴 animate">
		<div className="front-leg right">
			<div className="shoulder">
				<div className="upper">
					<div className="knee">
						<div className="lower">
							<div className="ankle">
								<div className="foot">
									<div className="hoof"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="back-leg right">
			<div className="top">
				<div className="thigh">
					<div className="lower-leg">
						<div className="foot">
							<div className="hoof"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="tail">
			<div className="nub">
				<div className="section">
				<div className="section">
				<div className="section">
				<div className="section">
				<div className="section">
				<div className="section last">
				</div>
				</div>
				</div>
				</div>
				</div>
				</div>
			</div>
		</div>
		<div className="body">
			<div className="section">
			<div className="section">
			<div className="section">
			<div className="section">
			<div className="section last">
			</div>
			</div>
			</div>
			</div>
			</div>
			<div className="back-side"></div>
		</div>
		
		<div className="neck">
			<div className="under"></div>
			<div className="front"></div>
			<div className="base"></div>
			<div className="top"></div>
			<div className="shoulder"></div>
		</div>
		<div className="front-leg left">
			<div className="shoulder">
				<div className="upper">
					<div className="knee">
						<div className="lower">
							<div className="ankle">
								<div className="foot">
									<div className="hoof"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div className="back-leg left">
			<div className="top">
				<div className="thigh">
					<div className="lower-leg">
						<div className="foot">
							<div className="hoof"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div className="head">
			<div className="skull"></div>
			<div className="nose"></div>
			<div className="face"></div>
			<div className="lip"></div>
			<div className="jaw"></div>
			<div className="chin"></div>
			<div className="ear"></div>
			<div className="eye"></div>
		</div>
	</div>	
	
	<div className="dust front">
		<div className="particle"></div>
		<div className="particle"></div>
		<div className="particle"></div>
		<div className="particle"></div>
		<div className="particle"></div>
		<div className="particle"></div>
		<div className="particle"></div>
		<div className="particle"></div>
		<div className="particle"></div>
		<div className="particle"></div>
		<div className="particle"></div>
		<div className="particle"></div>
		<div className="particle"></div>
		<div className="particle"></div>
		<div className="particle"></div>
		<div className="particle"></div>
		<div className="particle"></div>
		<div className="particle"></div>
		<div className="particle"></div>
		<div className="particle"></div>
		<div className="particle"></div>
		<div className="particle"></div>
		<div className="particle"></div>
		<div className="particle"></div>
		<div className="particle"></div>
		<div className="particle"></div>
		<div className="particle"></div>
		<div className="particle"></div>
		<div className="particle"></div>
		<div className="particle"></div>
	</div>
	<div className="dust back">
		<div className="particle"></div>
		<div className="particle"></div>
		<div className="particle"></div>
		<div className="particle"></div>
		<div className="particle"></div>
		<div className="particle"></div>
		<div className="particle"></div>
		<div className="particle"></div>
		<div className="particle"></div>
		<div className="particle"></div>
		<div className="particle"></div>
		<div className="particle"></div>
		<div className="particle"></div>
		<div className="particle"></div>
		<div className="particle"></div>
		<div className="particle"></div>
		<div className="particle"></div>
		<div className="particle"></div>
		<div className="particle"></div>
		<div className="particle"></div>
		<div className="particle"></div>
		<div className="particle"></div>
		<div className="particle"></div>
		<div className="particle"></div>
		<div className="particle"></div>
		<div className="particle"></div>
		<div className="particle"></div>
		<div className="particle"></div>
		<div className="particle"></div>
		<div className="particle"></div>
	</div>
	
	
</label>
    </div>
  );
};

export default Pertfolio;
