import { Route, Routes } from 'react-router-dom'
import Home from './components/Home/Index'
import Contact from './components/Contact/Index'
import Layout from './components/Layout/Index'
import './App.scss'
import About from './components/About/Index'
import Pertfolio from './components/Pertfolio/Index'
//import { Navbar } from "./components/Navbar1.js/Navbar"


function App() {
  return (
    <>
    
     <Routes>
        <Route path="/" element={<Layout />}>
         <Route index element={<Home />} />
          <Route path="about" element={<About />} />
   <Route path='Portfolio' element={<Pertfolio />} />  
          <Route path="/contact" element={<Contact />} />
        
        </Route>
  </Routes> 
  { /*  <Navbar/>*/}
    </>
  )
}

export default App
