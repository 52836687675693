import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters/Index'
import LogoTitle from '../../assets/images/jamesremove.png'
import Logo from './Logo/Index'
import './Index.scss'
import './Background.scss'
const Home = () => {
  const [letterClass, setLetterClass] = useState('text-animate')

  const nameArray = ['J', 'a', 'm', 'e', 's', ' ', 'H', 'a','r', 'r','i','o','t','😀']
  const jobArray = [
    'w',
    'e',
    'b',
    ' ',
    'd',
    'e',
    'v',
    'e',
    'l',
    'o',
    'p',
    'e',
    'r',
    '.',
  ]

  useEffect(() => {
    return setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 4000)
  }, [])

  return (
    <>
           <input type="checkbox" id="shadows" checked /><label htmlFor="shadows">Soft shadows</label>
<div className="cubes">
  
  <div className="cube" data-cube="111">
    <div className="cube-wrap">
      <div className="cube-top">
        <div className="shadow-z" data-cube="112"></div>
      </div>
      <div className="cube-bottom"></div>
      <div className="cube-front-left"></div>
      <div className="cube-front-right"></div>
      <div className="cube-back-left"></div>
      <div className="cube-back-right"></div>
    </div>
  </div>
  <div className="cube" data-cube="121">
    <div className="cube-wrap">
      <div className="cube-top">
      </div>
      <div className="cube-bottom"></div>
      <div className="cube-front-left"></div>
      <div className="cube-front-right"></div>
      <div className="cube-back-left"></div>
      <div className="cube-back-right"></div>
    </div>
  </div>
  <div className="cube" data-cube="131">
    <div className="cube-wrap">
      <div className="cube-top">
        <div className="shadow-z" data-cube="132"></div>
      </div>
      <div className="cube-bottom"></div>
      <div className="cube-front-left"></div>
      <div className="cube-front-right"></div>
      <div className="cube-back-left"></div>
      <div className="cube-back-right"></div>
    </div>
  </div>
  <div className="cube" data-cube="211">
    <div className="cube-wrap">
      <div className="cube-top">
        <div className="shadow-flip" data-cube="111"></div>
        <div className="shadow-y" data-cube="111"></div>
        <div className="shadow-z" data-cube="212"></div>
      </div>
      <div className="cube-bottom"></div>
      <div className="cube-front-left"></div>
      <div className="cube-front-right"></div>
      <div className="cube-back-left"></div>
      <div className="cube-back-right"></div>
    </div>
  </div>
  <div className="cube" data-cube="221">
    <div className="cube-wrap">
      <div className="cube-top">
        <div className="shadow-flip" data-cube="121"></div>
        <div className="shadow-y" data-cube="121"></div>
      </div>
      <div className="cube-bottom"></div>
      <div className="cube-front-left"></div>
      <div className="cube-front-right"></div>
      <div className="cube-back-left"></div>
      <div className="cube-back-right"></div>
    </div>
  </div>
  <div className="cube" data-cube="231">
    <div className="cube-wrap">
      <div className="cube-top">
        <div className="shadow-flip" data-cube="131"></div>
        <div className="shadow-y" data-cube="131"></div>
      </div>
      <div className="cube-bottom"></div>
      <div className="cube-front-left"></div>
      <div className="cube-front-right"></div>
      <div className="cube-back-left"></div>
      <div className="cube-back-right"></div>
    </div>
  </div>
  <div className="cube" data-cube="311">
    <div className="cube-wrap">
      <div className="cube-top">
        <div className="shadow-flip" data-cube="211"></div>
        <div className="shadow-y" data-cube="211"></div>
        <div className="shadow-z" data-cube="312"></div>
      </div>
      <div className="cube-bottom"></div>
      <div className="cube-front-left"></div>
      <div className="cube-front-right"></div>
      <div className="cube-back-left"></div>
      <div className="cube-back-right"></div>
    </div>
  </div>
  <div className="cube" data-cube="321">
    <div className="cube-wrap">
      <div className="cube-top">
        <div className="shadow-flip" data-cube="221"></div>
        <div className="shadow-y" data-cube="221"></div>
        <div className="shadow-z" data-cube="322"></div>
      </div>
      <div className="cube-bottom"></div>
      <div className="cube-front-left"></div>
      <div className="cube-front-right"></div>
      <div className="cube-back-left"></div>
      <div className="cube-back-right"></div>
    </div>
  </div>
  <div className="cube" data-cube="331">
    <div className="cube-wrap">
      <div className="cube-top">
        <div className="shadow-flip" data-cube="231"></div>
        <div className="shadow-y" data-cube="231"></div>
        <div className="shadow-z" data-cube="332"></div>
      </div>
      <div className="cube-bottom"></div>
      <div className="cube-front-left"></div>
      <div className="cube-front-right"></div>
      <div className="cube-back-left"></div>
      <div className="cube-back-right"></div>
    </div>
  </div>

  <div className="cube" data-cube="112">
    <div className="cube-wrap">
      <div className="cube-top">

      </div>
      <div className="cube-bottom"></div>
      <div className="cube-front-left"></div>
      <div className="cube-front-right"></div>
      <div className="cube-back-left"></div>
      <div className="cube-back-right"></div>
    </div>
  </div>
  <div className="cube" data-cube="122">
    <div className="cube-wrap">
      <div className="cube-top">
      </div>
      <div className="cube-bottom"></div>
      <div className="cube-front-left"></div>
      <div className="cube-front-right"></div>
      <div className="cube-back-left"></div>
      <div className="cube-back-right"></div>
    </div>
  </div>
  <div className="cube" data-cube="132">
    <div className="cube-wrap">
      <div className="cube-top">
      </div>
      <div className="cube-bottom"></div>
      <div className="cube-front-left"></div>
      <div className="cube-front-right"></div>
      <div className="cube-back-left"></div>
      <div className="cube-back-right"></div>
    </div>
  </div>
  <div className="cube" data-cube="212">
    <div className="cube-wrap">
      <div className="cube-top">
        <div className="shadow-flip" data-cube="112"></div>
        <div className="shadow-y" data-cube="112"></div>
      </div>
      <div className="cube-bottom"></div>
      <div className="cube-front-left"></div>
      <div className="cube-front-right"></div>
      <div className="cube-back-left"></div>
      <div className="cube-back-right"></div>
    </div>
  </div>
  <div className="cube" data-cube="222">
    <div className="cube-wrap">
      <div className="cube-top">
        <div className="shadow-flip" data-cube="122"></div>
        <div className="shadow-y" data-cube="122"></div>
      </div>
      <div className="cube-bottom"></div>
      <div className="cube-front-left"></div>
      <div className="cube-front-right"></div>
      <div className="cube-back-left"></div>
      <div className="cube-back-right"></div>
    </div>
  </div>
  <div className="cube" data-cube="232">
    <div className="cube-wrap">
      <div className="cube-top">
        <div className="shadow-flip" data-cube="132"></div>
        <div className="shadow-y" data-cube="132"></div>
      </div>
      <div className="cube-bottom"></div>
      <div className="cube-front-left"></div>
      <div className="cube-front-right"></div>
      <div className="cube-back-left"></div>
      <div className="cube-back-right"></div>
    </div>
  </div>
  <div className="cube" data-cube="312">
    <div className="cube-wrap">
      <div className="cube-top">
        <div className="shadow-flip" data-cube="212"></div>
        <div className="shadow-y" data-cube="212"></div>
      </div>
      <div className="cube-bottom"></div>
      <div className="cube-front-left"></div>
      <div className="cube-front-right"></div>
      <div className="cube-back-left"></div>
      <div className="cube-back-right"></div>
    </div>
  </div>
  <div className="cube" data-cube="322">
    <div className="cube-wrap">
      <div className="cube-top">
        <div className="shadow-flip" data-cube="222"></div>
        <div className="shadow-y" data-cube="222"></div>
      </div>
      <div className="cube-bottom"></div>
      <div className="cube-front-left"></div>
      <div className="cube-front-right"></div>
      <div className="cube-back-left"></div>
      <div className="cube-back-right"></div>
    </div>
  </div>
  <div className="cube" data-cube="332">
    <div className="cube-wrap">
      <div className="cube-top">
        <div className="shadow-flip" data-cube="232"></div>
        <div className="shadow-y" data-cube="232"></div>
      </div>
      <div className="cube-bottom"></div>
      <div className="cube-front-left"></div>
      <div className="cube-front-right"></div>
      <div className="cube-back-left"></div>
      <div className="cube-back-right"></div>
    </div>
  </div>
  <div className="cube" data-cube="113">
    <div className="cube-wrap">
      <div className="cube-top">
        <div className="shadow-z" data-cube="111"></div>
      </div>
      <div className="cube-bottom"></div>
      <div className="cube-front-left"></div>
      <div className="cube-front-right"></div>
      <div className="cube-back-left"></div>
      <div className="cube-back-right"></div>
    </div>
  </div>
  <div className="cube" data-cube="123">
    <div className="cube-wrap">
      <div className="cube-top">
        <div className="shadow-z" data-cube="121"></div>
      </div>
      <div className="cube-bottom"></div>
      <div className="cube-front-left"></div>
      <div className="cube-front-right"></div>
      <div className="cube-back-left"></div>
      <div className="cube-back-right"></div>
    </div>
  </div>
  <div className="cube" data-cube="133">
    <div className="cube-wrap">
      <div className="cube-top">
      </div>
      <div className="cube-bottom"></div>
      <div className="cube-front-left"></div>
      <div className="cube-front-right"></div>
      <div className="cube-back-left"></div>
      <div className="cube-back-right"></div>
    </div>
  </div>
  <div className="cube" data-cube="213">
    <div className="cube-wrap">
      <div className="cube-top">
        <div className="shadow-flip" data-cube="113"></div>
        <div className="shadow-y" data-cube="113"></div>
        <div className="shadow-z" data-cube="211"></div>
      </div>
      <div className="cube-bottom"></div>
      <div className="cube-front-left"></div>
      <div className="cube-front-right"></div>
      <div className="cube-back-left"></div>
      <div className="cube-back-right"></div>
    </div>
  </div>
  <div className="cube" data-cube="223">
    <div className="cube-wrap">
      <div className="cube-top">
        <div className="shadow-y" data-cube="123"></div>
        <div className="shadow-z" data-cube="221"></div>
      </div>
      <div className="cube-bottom"></div>
      <div className="cube-front-left"></div>
      <div className="cube-front-right"></div>
      <div className="cube-back-left"></div>
      <div className="cube-back-right"></div>
    </div>
  </div>
  <div className="cube" data-cube="233">
    <div className="cube-wrap">
      <div className="cube-top">
        <div className="shadow-y" data-cube="133"></div>
        <div className="shadow-z" data-cube="231"></div>
      </div>
      <div className="cube-bottom"></div>
      <div className="cube-front-left"></div>
      <div className="cube-front-right"></div>
      <div className="cube-back-left"></div>
      <div className="cube-back-right"></div>
    </div>
  </div>
  <div className="cube" data-cube="313">
    <div className="cube-wrap">
      <div className="cube-top">
        <div className="shadow-flip" data-cube="213"></div>
        <div className="shadow-y" data-cube="213"></div>
        <div className="shadow-z" data-cube="311"></div>
      </div>
      <div className="cube-bottom"></div>
      <div className="cube-front-left"></div>
      <div className="cube-front-right"></div>
      <div className="cube-back-left"></div>
      <div className="cube-back-right"></div>
    </div>
  </div>
  <div className="cube" data-cube="323">
    <div className="cube-wrap">
      <div className="cube-top">
        <div className="shadow-flip" data-cube="223"></div>
        <div className="shadow-y" data-cube="223"></div>
        <div className="shadow-z" data-cube="321"></div>
      </div>
      <div className="cube-bottom"></div>
      <div className="cube-front-left"></div>
      <div className="cube-front-right"></div>
      <div className="cube-back-left"></div>
      <div className="cube-back-right"></div>
    </div>
  </div>
  <div className="cube" data-cube="333">
    <div className="cube-wrap">
      <div className="cube-top">
        <div className="shadow-flip" data-cube="233"></div>
        <div className="shadow-y" data-cube="233"></div>
        <div className="shadow-z" data-cube="331"></div>
      </div>
      <div className="cube-bottom"></div>
      <div className="cube-front-left"></div>
      <div className="cube-front-right"></div>
      <div className="cube-back-left"></div>
      <div className="cube-back-right"></div>
    </div>
  </div>
  
  <div className="large-shadows">
    <div className="large-shadow" data-cube="113"></div>
    <div className="large-shadow" data-cube="123"></div>
    <div className="large-shadow" data-cube="133"></div>
    <div className="large-shadow" data-cube="213"></div>
    <div className="large-shadow" data-cube="223"></div>
    <div className="large-shadow" data-cube="233"></div>
    <div className="large-shadow" data-cube="313"></div>
    <div className="large-shadow" data-cube="323"></div>
    <div className="large-shadow" data-cube="333"></div>
  </div>
</div>
      <div className="container home-page">
        <div className="text-zone">
          <h1>
            <span className={letterClass}>H</span>
            <span className={`${letterClass} _12`}>i,</span>
            <br />
            <span className={`${letterClass} _15`}>I</span>
            <span className={`${letterClass} _17`}>'m</span>
            <img
              src={LogoTitle}
              alt="JavaScript Developer Name, Web Developer Name"
            />
            <AnimatedLetters
              letterClass={letterClass}
              strArray={nameArray}
              idx={15}
            />
            <br />
            <AnimatedLetters
              letterClass={letterClass}
              strArray={jobArray}
              idx={22}
            />
          </h1>
          <h2>Html / Css / JavaScript Basics / React </h2>
          <Link style={{fontFamily:'La Belle Aurore '}} to="/contact" className="flat-button">
            CONTACT ME
          </Link>
        </div>
        <Logo />
      </div>

      <Loader type="pacman" />
    </>
  )
}

export default Home
