import { useEffect, useState } from 'react'
import Loader from 'react-loaders'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { useRef } from 'react'
import emailjs from '@emailjs/browser'
import AnimatedLetters from '../AnimatedLetters/Index'
import './Index.scss'
//import Index from '../Mousetrail/Index'

const Contact = () => {
  const [letterClass, setLetterClass] = useState('text-animate')
  const form = useRef()

  useEffect(() => {
    return setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 3000)
  }, [])
  const sendEmail = (e) => {
    e.preventDefault();
  
    const emailParams = {
      service_id: 'service_w0ali4c',
      template_id: 'template_wb7g8dv',
      user_id: 'T3_QTRnNpPn6lx0fX',
      template_params: {
        from_name: form.James.name.value,
        from_email: form.ia8214652.com.gmail.value,
        subject: form.current.subject.value,
        message: form.current.message.value,
      },
    };
  
    emailjs.send('gmail', 'template_wb7g8dv', emailParams)
      .then(
        () => {
          alert('Email gi chali hai/Message successfully sent!');
          form.current.reset();
        },
        () => {
          alert('Email send nahi howi hai/Dobara khosish kry / tu Mera put chUti kr/Failed to send the message, please try again');
        }
      );
  };
  
  return (
    <>


      <div className="container contact-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={['C', 'o', 'n', 't', 'a', 'c', 't', ' ', 'm', 'e']}
              // the words are showing animated bcs of this AnimatedLetters
              
              idx={15}
            />
          </h1>
          <h4 style={{fontSize:'18px'}}>
            My name is Imran ali 
          </h4>
          <div className="contact-form">
            <form ref={form} onSubmit={sendEmail}>
              <ul>
                <li className="half">
                  <input placeholder="Name" type="text" name="name" required />
                </li>
                <li className="half">
                  <input
                    placeholder="Email"
                    type="email"
                    name="email"
                    required
                  />
                </li>
                <li>
                  <input
                    placeholder="Subject"
                    type="text"
                    name="subject"
                    required
                  />
                </li>
                <li>
                  <textarea
                    placeholder="Message"
                    name="message"
                    required
                  ></textarea>
                </li>
                <li>
                  <input type="submit" className="flat-button" value="SEND" />
                </li>
              </ul>
            </form>
          </div>
        </div>
        <div className="info-map">
          James
          <br />
          Harriot,
          <br />
          Chungi Amar sadhu<br />
          Sitra Colany no 2 House No. 33 <br />
          <br />
          <span>ia8214652@gmail.com</span>
        </div>
      
        <div className="map-wrap">
          <MapContainer center={[31.4451, 74.3589]} zoom={13}>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <Marker position={[44.96366, 19.61045]}>
              <Popup>This is the my Home Location Don't Come Here </Popup>
            </Marker>
          </MapContainer>
        </div>
      </div>
   {/*   <Index/> */}
      <Loader type="pacman" />
      
    </>
  )
}

export default Contact;
