import { Outlet } from 'react-router-dom'
import Sidebar from '../Sidebar/Index'
import './Index.scss'

const Layout = () => {
  return (
    <div className="App">
      <Sidebar />
      <div className="page">
        <span className="tags top-tags">&lt; Hello Yaar Asslam O ALaikum &gt;</span>
        <span className="tags top-tags">&lt; Tu kia Haal chaal hain aap ke &gt;</span>
        <Outlet />
        <span className="tags bottom-tags">
          &lt;My Name Is james&gt;
          <br />
          <span className="bottom-tag-html">&lt;I hope you will like my poertfolio &gt;</span>
          <span className="bottom-tag-html">&lt;this is from Layout&gt;</span>
        </span>
      </div>
    </div>
  )
}

export default Layout




